import React from "react";
import { Button, Tag } from "@chakra-ui/react";
import { PlanConfig, PlanTier } from "@productflint/api/src/types/Plan";

import { Link } from "components/Link";
import { FeatureLimit } from "components/Pricing/components/FeatureLimit";
import { P, Span } from "components/Typography";
import { routes } from "constants/routes";
import { features } from "utils/pricing";

interface PricingCardProps {
  showMonthly: boolean;
  planConfig: PlanConfig;
}

export const PricingCard: React.FC<PricingCardProps> = ({
  showMonthly,
  planConfig,
}) => {
  const { description, priceYear, priceMonth, tier, label } = planConfig;

  const isSpark = tier === PlanTier.SPARK;
  const savings = (priceMonth * 12 - priceYear).toFixed(2);

  return (
    <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10">
      <div className="border-primary shadow-pricing relative z-10 overflow-hidden rounded-xl border border-opacity-20 bg-white py-10 px-8 sm:p-12 lg:py-10 lg:px-6 xl:p-12">
        {isSpark && (
          <div className="absolute w-full bg-sky-100 top-0 left-0 uppercase text-center">
            <Span
              fontSize="xs"
              tracking="wide"
              fontWeight="medium"
              fontColor="secondary"
            >
              Most Popular
            </Span>
          </div>
        )}
        <span className="text-primary mb-4 block text-lg font-semibold text-center">
          {label}
        </span>
        <h2 className="text-dark mb-5 text-[42px] font-bold text-center">
          ${showMonthly ? priceMonth : priceYear / 12}
          <span className="text-body-color text-base font-medium">/month</span>
        </h2>
        {!showMonthly && (
          <div className="h-12">
            {tier !== PlanTier.FREE && (
              <Tag variant="outline" colorScheme="green" className="mb-5">
                Save ${savings}
              </Tag>
            )}
          </div>
        )}
        <P
          fontColor="secondary"
          className="mb-6 border-b border-[#F2F2F2] pb-6 text-center"
        >
          {description}
        </P>
        <div className="mb-7 flex flex-col gap-6 items-center">
          {features
            .filter(({ toolKey }) => !!toolKey)
            .map(({ label, toolKey, word, hidePerMonth }) => (
              <div key={toolKey}>
                <P fontWeight="medium" className="text-center mb-1">
                  {label}
                </P>
                <FeatureLimit
                  featureKey={toolKey}
                  plan={planConfig}
                  word={word}
                  hidePerMonth={hidePerMonth}
                />
              </div>
            ))}
        </div>
        <Link href={routes.pricing.href} isPlain>
          <Button
            size="lg"
            colorScheme="blue"
            variant={isSpark ? "solid" : "outline"}
            className="w-full"
          >
            Explore {label}
          </Button>
        </Link>
      </div>
    </div>
  );
};
