import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { MonthlyToolUseCounts } from "@productflint/api/src/types/app/User";
import { PlanConfig } from "@productflint/api/src/types/Plan";
import clsx from "clsx";

import { Span } from "components/Typography";

interface FeatureLimitProps {
  plan: PlanConfig;
  featureKey?: keyof MonthlyToolUseCounts;
  word?: string;
  hidePerMonth?: boolean;
}

export const FeatureLimit: React.FC<FeatureLimitProps> = ({
  featureKey,
  plan,
  word,
  hidePerMonth,
}) => {
  const limit = featureKey ? plan.monthlyLimits[featureKey] : Infinity;

  const formattedLimit = featureKey
    ? plan.monthlyLimits[featureKey] === Infinity
      ? "Unlimited"
      : plan.monthlyLimits[featureKey]
    : "Unlimited";

  return (
    <div className="flex items-center gap-2">
      {limit > 0 ? (
        <span
          className={clsx("text-xs", {
            "text-green-600": limit > 50,
            "text-yellow-600": limit < 50,
          })}
        >
          <FaCheck />
        </span>
      ) : (
        <span className="text-xs text-red-600">
          <FaTimes />
        </span>
      )}
      <div className="flex gap-1 items-center">
        {formattedLimit} {word}
        {formattedLimit !== "Unlimited" && !hidePerMonth && (
          <Span fontSize="sm" fontColor="secondary">
            /month
          </Span>
        )}
      </div>
    </div>
  );
};
