import React from "react";

import { H1 } from "components/Typography";

export const Title = () => (
  <div className="relative">
    <H1 fontSize="6xl" className="hero" fontWeight="bold">
      Grow your Etsy business
      <span className="hero-etsy relative">.</span>
    </H1>
  </div>
);
