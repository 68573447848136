import React from "react";
import { Switch, Tag } from "@chakra-ui/react";
import { PLAN_CONFIGS } from "@productflint/api/src/constants";

import { Container } from "components/Layout/Container";
import { PricingCard } from "components/Pricing/components/PricingCard";
import { H2, P } from "components/Typography";
import { config } from "constants/app";

export const Pricing = () => {
  const [showMonthly, setShowMonthly] = React.useState(false);

  return (
    <Container className="mb-10 border-t border-gray-200 py-16 text-center">
      <div className="-mx-4 flex flex-wrap">
        <div className="mx-auto mb-12 text-center">
          <P
            fontSize="md"
            fontColor="accent"
            fontWeight="medium"
            className="mb-6"
          >
            {config.appName} Pricing
          </P>
          <H2>Our Pricing Plans</H2>
          <P fontSize="lg" fontColor="secondary" className="mt-6">
            Pricing plans for every stage of your Etsy journey.
          </P>
          <div className="flex justify-center gap-4 items-center mt-10">
            <P fontSize="md" fontColor="secondary" fontWeight="medium">
              Monthly
            </P>
            <Switch
              isChecked={!showMonthly}
              onChange={(event) => {
                setShowMonthly(!event.target.checked);
              }}
              size="lg"
            />
            <P fontSize="md" fontColor="secondary" fontWeight="medium">
              Yearly
            </P>
          </div>
          <Tag variant="outline" colorScheme="green" className="mt-4">
            Save up to 40% by paying yearly
          </Tag>
        </div>
      </div>
      <div className="flex flex-wrap justify-center items-stretch">
        {Object.values(PLAN_CONFIGS).map((planConfig) => (
          <PricingCard
            key={planConfig.tier}
            planConfig={planConfig}
            showMonthly={showMonthly}
          />
        ))}
      </div>
    </Container>
  );
};
