import React from "react";
import { Button, Flex, Stack } from "@chakra-ui/react";
import type { NextPage } from "next";

import { FeatureList } from "components/FeatureList";
import { Container } from "components/Layout/Container";
import { Link } from "components/Link";
import { Footer } from "components/Navigation/Footer";
import { NavBar } from "components/Navigation/NavBar";
import { Pricing } from "components/Pricing";
import { Title } from "components/Splash/Title";
import { H2, P } from "components/Typography";
import { config, isFeatureEnabled } from "constants/app";
import { routes } from "constants/routes";
import { useAuth } from "hooks/useFirebaseAuth";

const Home: NextPage = () => {
  const { authUser } = useAuth();

  return (
    <>
      <NavBar startTransparent={true} />
      <div className="bg-slate-50 pt-20 home-bg max-w-[100vw] overflow-hidden">
        <Container className="px-0 sm:px-6 lg:px-8">
          <Stack
            className="min-h-[750px]"
            direction={{ base: "column", md: "row" }}
          >
            <Flex p={8} flex={1} align="center" justify="center">
              <Stack
                spacing={6}
                w="full"
                maxW="lg"
                className="text-center md:text-left"
              >
                <Title />
                <P fontSize="xl" fontColor="secondary" fontWeight="medium">
                  The simple all-in-one platform to help you grow on Etsy. Easy
                  for beginners, powerful enough for Etsy veterans.
                </P>
                <Stack direction={{ base: "column", md: "row" }} spacing={4}>
                  {authUser ? (
                    <Link
                      isPlain
                      href={routes.dashboard.href}
                      className="w-full md:w-fit"
                    >
                      <Button size="lg" colorScheme="blue" className="w-full">
                        My Account
                      </Button>
                    </Link>
                  ) : (
                    <Link
                      isPlain
                      href={routes.signUp.href}
                      className="w-full md:w-fit"
                    >
                      <Button size="lg" colorScheme="blue" className="w-full">
                        Sign up for free
                      </Button>
                    </Link>
                  )}
                  <Link isPlain href="#overview" className="w-full md:w-fit">
                    <Button
                      size="lg"
                      colorScheme="blue"
                      variant="outline"
                      className="w-full md:w-fit !bg-white"
                    >
                      Explore our tools
                    </Button>
                  </Link>
                </Stack>
                {/*<div className="text-sm font-medium text-gray-600 flex items-center">*/}
                {/*  <img*/}
                {/*    className="h-8 w-8 rounded-full mr-2"*/}
                {/*    src="/avatar.jpg"*/}
                {/*    alt="avatar"*/}
                {/*  />*/}
                {/*  Trusted by 1325+ Etsy sellers*/}
                {/*</div>*/}
              </Stack>
            </Flex>
            <Flex flex={1} className="relative">
              <img
                className="px-10 object-contain block md:hidden"
                alt="SignIn Image"
                src="/tablet-splash.webp"
              />
              <img
                className="absolute hidden md:block h-auto left-0 max-w-[750px] top-1/2 -translate-y-1/2"
                alt="SignIn Image"
                src="/tablet-splash.webp"
              />
            </Flex>
          </Stack>
        </Container>
      </div>
      <Container id="overview" className="py-16 px-4 text-center">
        <P
          fontSize="md"
          fontColor="accent"
          fontWeight="medium"
          className="mb-6"
        >
          {config.appName} Overview
        </P>
        <H2>The all-in-one platform for selling on Etsy</H2>
        <P
          fontSize="lg"
          fontColor="secondary"
          className="mt-6"
          fontWeight="medium"
        >
          Explore all the features you need to start, run, and grow your Etsy
          shop.
        </P>
        <div className="mt-8">
          <img src="/features/keyword-finder.png" />
        </div>
        <FeatureList />
      </Container>
      {isFeatureEnabled("enablePricing") && <Pricing />}
      <Footer />
    </>
  );
};

export default Home;
