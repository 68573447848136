import { MonthlyToolUseCounts } from "@productflint/api/src/types/app/User";

export const features: {
  label: string;
  toolKey?: keyof MonthlyToolUseCounts;
  word?: string;
  hidePerMonth?: boolean;
}[] = [
  {
    label: "Keyword Finder",
    toolKey: "keywordFinderCountMonth",
    word: "searches",
  },
  {
    label: "Listing Optimizer",
    toolKey: "listingOptimizerCountMonth",
    word: "listings",
    hidePerMonth: true,
  },
  {
    label: "Shop Analyzer",
    toolKey: "shopAnalyzerCountMonth",
    word: "searches",
  },
  {
    label: "Listing Explorer",
    toolKey: "listingAnalyzerCountMonth",
    word: "searches",
  },
  {
    label: "Tag Generator",
    word: "tags",
  },
  {
    label: "Tag Extractor",
    word: "searches",
  },
];
